import * as React from "react";
import "./Top.scss";

const Top = () => {
  return (
    <div className="topContainer">
      <h1>Silbo Herreño</h1>
    </div>
  );
};

export default Top;
